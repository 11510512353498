const base = require('./base');
console.log('live config');
module.exports = {
  player: {
    ...base.player,

    autoplay: true,
    controls: [
      'play-large',
      'play',
      'mute',
      'volume',
      'settings',
      'fullscreen',
    ]
  },
  hls: {
    ...base.hls,

    lowLatencyMode: true,
    liveDurationInfinity: true,
    liveSyncDuration: 6,
    liveMaxLatencyDuration: 18,

    startFragPrefetch: true,

    maxLiveSyncPlaybackRate: 1.05
  },
};
