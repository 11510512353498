const base = require('./base');

module.exports = {
  player: {
    ...base.player,
  },
  hls: {
    ...base.hls,
  },
};
