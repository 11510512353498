<template>
  <div class="container">

    <div v-if="sfn" class="sfn-container">

      <div class="sfn-header">
        Stats for Nerds
      </div>

      <div class="sfn-body html5-video-info-panel-content">
        <div>
          <div>Video ID</div>
          <span>{{ video.id }}</span></div>
        <div>
          <div>Viewport / Frames</div>
          <span>{{
              player.elements ?
              `${player.elements.container.clientWidth}x${player.elements.container.clientHeight}`
                              : 'loading'
            }} / {{ player.media.webkitDroppedFrameCount }} dropped of {{ player.media.webkitDecodedFrameCount }}</span>
        </div>
        <div>
          <div>Current / Optimal Res</div>
          <span>{{
              hlsLevel.width ? `${hlsLevel.width}x${hlsLevel.height}` : 'loading'
            }} / {{
              hlsLevel.width ? `${hlsLevel.width}x${hlsLevel.height}` : 'loading'
            }}</span></div>
        <div>
          <div>Volume / Normalized</div>
          <span>{{ (player.volume * 100 || 0).toFixed() }}% / {{ (player.volume * 100 || 0).toFixed() }}%</span>
        </div>

        <br>


        <div>
          <div>Network / Edge</div>
          <span>{{ contentNetwork }} / {{ network.edgeId }}</span>
        </div>

        <div style="">
          <div>Connection Speed</div>
          <span>
            <span v-if="false">
              <div class="ytp-horizonchart" style="width: 300px; height: 1em;">
                <canvas width="300" style="width: 300px; height: 11px;" height="22"></canvas>
              </div>
            </span>

            <span>{{ ((hls.bandwidthEstimate || 0) / (1000 * 1000)).toFixed(2) }} Mbps</span>
          </span>
        </div>
        <div>
          <div>Network Activity</div>
          <span>
            <span v-if="false">
              <div class="ytp-horizonchart" style="width: 300px; height: 1em;">
                <canvas width="300" style="width: 300px; height: 11px;" height="22"></canvas>
              </div>
            </span>

            <span>{{ 0 }} Mbps</span>
          </span>
        </div>

        <br>

        <div v-if="contentType === 'live'">
          <div>Live Latency</div>
          <span>
            <span v-if="false">
              <div class="ytp-horizonchart" style="width: 300px; height: 1em;">
                <canvas width="300" style="width: 300px; height: 11px;" height="22"></canvas>
              </div>
            </span>

            <span>
              {{ (hls.latency || 0).toFixed(2) }}s
              (est. {{ ((hls.latency || 0) + 4).toFixed(2) }}s, ext. {{ (hlsStatsLatency).toFixed(2) }})

              {{ (hls.targetLatency || 0).toFixed(2) }}s
              {{ ((hls.maxLatency || 0) + 4).toFixed(2) }}s
            </span>
          </span>
        </div>

        <div v-if="contentType === 'live'">
          <div>Live Drift Rate</div>
          <span>
            <span v-if="false">
              <div class="ytp-horizonchart" style="width: 300px; height: 1em;">
                <canvas width="300" style="width: 300px; height: 11px;" height="22"></canvas>
              </div>
            </span>

            <span>
              {{ (hls.drift || 0).toFixed(2) }}x
            </span>
          </span>
        </div>


        <div>
          <div>Mystery Text</div>
          <span>t:{{ hlsStats.position.toFixed(2) }} r:{{ (player.media.playbackRate || 0).toFixed(2) }}</span>
        </div>
      </div>
    </div>


    <vue-plyr ref="plyr" :options="options" class="player">

      <video
          ref="video"
          :data-poster="options.poster"
          controls
          crossorigin
          playsinline
      >

        <source
            :src="hlsPlayBackUrl"
            type="application/x-mpegURL"
        />

        <track v-for="caption in captions"
               :key="caption.url"
               :label="caption.label"
               :src="caption.url"
               :srclang="caption.language"
               default
               kind="captions"
        />
      </video>

    </vue-plyr>
  </div>
</template>

<style>


.html5-video-info-panel {
  background: rgba(28, 28, 28, 0.8);
  border-radius: 4px;
  color: #fff;
  left: 10px;
  position: absolute;
  top: 10px;
  z-index: 64;
  min-width: 26em
}

.html5-video-info-panel-close {
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 5px
}

.html5-video-info-panel-content {
  padding: 5px
}

.html5-video-info-panel-content > div > div {
  display: inline-block;
  font-weight: 500;
  padding: 0 .5em;
  text-align: right;
  width: 10em
}

.ytp-horizonchart {
  display: inline-block;
  margin: 2px;
  position: relative;
  vertical-align: bottom
}

.ytp-horizonchart > span {
  display: inline-block;
  position: absolute
}

.sfn-container {
  z-index: 2147483647;
  position: absolute;
  left: 25px;
  top: 25px;
  width: 35rem;
  background-color: rgba(0, 0, 0, .8);
  color: rgb(255, 255, 255)
}

.sfn-header {
  text-align: center;
  margin-top: 5px;
}

.container, .player, .plyr {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

:root {
  --plyr-color-main: #1b48b3;
}

</style>

<script>

import VuePlyr from '../components/VuePlyr';
import {v4 as uuidv4} from 'uuid';

export default {
  name: 'ContentPlayer',
  components: {
    VuePlyr,
  },
  computed: {
    contentType: function() {
      return this.$route.params.contentType;
    },
    videoId: function() {
      return this.$route.params.videoId;
    },
    hlsPlayBackUrl: function() {
      return `https://serve.osidex.delivery/${this.contentType}/${this.videoId}?network=${this.contentNetwork}`;
    },
    hlsStatsLatency: function() {

      if (this.hls.abrController) {
        if (this.hls.abrController.fragCurrent) {
          let frag = this.hls.abrController.fragCurrent;

          const currentPDT = frag.programDateTime + (this.player.media.currentTime - frag.start) * 1000;

          return (Date.now() - currentPDT) / 1000;
        }
      }

      return 0;
    },
  },
  data: function() {

    const config = require(`../configs/${this.$route.params.contentType || 'base'}`);

    return {
      options: config.player,
      hlsConfig: config.hls,
      video: {},
      captions: [],

      sfn: false,

      player: {media: {}},
      hls: {},

      contentNetwork: 'verge',

      network: {
        edgeId: 'init',
      },

      hlsLevel: {},
      hlsStats: {
        framesTotal: 0,
        framesDropped: 0,
        position: 0,
      },
    };
  },
  methods: {

    parseUrlParams() {

      const {autoplay, muted, poster, latency, network} = this.$route.query;

      if (autoplay === 'false' || autoplay === '0' || autoplay === 'no') {
        this.options.autoplay = false;
      } else if (autoplay === 'true' || autoplay === '1' || autoplay === 'yes') {
        this.options.autoplay = true;
      }

      if (muted === 'true' || autoplay === '1' || autoplay === 'yes') {
        this.options.muted = true;
      }

      if (network === 'verge') {
        this.contentNetwork = 'verge';
      }

      if (poster) {
        this.options.poster = poster;
        if (window.player) {
          window.player.poster = poster;
        }
      }

      // Start Live Options

      if (this.contentType === 'live') {

        if (latency === 'low') {
          this.hlsConfig.liveSyncDuration = 4;
          this.hlsConfig.liveMaxLatencyDuration = 12;
        } else if (latency === 'normal') {
          this.hlsConfig.liveSyncDuration = 6;
          this.hlsConfig.liveMaxLatencyDuration = 18;
        } else if (latency === 'high') {
          this.hlsConfig.liveSyncDuration = 12;
          this.hlsConfig.liveMaxLatencyDuration = 30;
        }

      }

    },

    async loadVideoFromApi() {

      try {

        const response = await this.$http.get(`https://api.osidex.stream/videos/${this.videoId}`);
        const {data} = response;

        this.video = data.data;
        document.title = this.video.title || this.video.id;

        if (this.video.poster_url) {
          const {poster_url} = this.video;
          this.options.poster = poster_url;
          if (window.player) {
            window.player.poster = poster_url;
          }
        }

        if (this.video.type === 'live') {
          this.contentNetwork = 'verge';
        }

        return this.video;
      } catch (e) {
        // We care about errors, but it shouldn't break the player if our API service fails to respond.
        console.error(e);
      }

      return undefined;
    },

    async doSecurityChecks() {
      const video = await this.loadVideoFromApi();

      if (!video) {
        return false;
      }

      const security = video.security || {};

      if (security.allowed_origins && security.allowed_origins.length > 0) {

        const url = new URL(document.referrer || location.href);
        const origin = url.hostname;

        if (!security.allowed_origins.includes(origin)) {
          return false;
        }

      }

      if (video.status !== 'ready') {

        if (video.type === 'live') {
          setTimeout(() => {
            // Refresh the page after 10 seconds to check if we have obtained a new stream status
            location.reload();
          }, 10_000);
        }

        return false;
      }

      return true;
    },

    doPlayerMounting() {
      // Oh JavaScript how I love you <3
      const _ref = this;

      // eslint-disable-next-line
      if (Hls.isSupported()) {
        try {
          this.hls.attachMedia(this.$refs.video);

          // eslint-disable-next-line no-undef
          this.hls.on(Hls.Events.ERROR, function(event, data) {
            // const errorDetails = data.details;
            const errorFatal = data.fatal;

            if (errorFatal) {
              // Very unclean but gotta do what you gotta do until you have better options
              location.reload();
            }

          });

          // eslint-disable-next-line no-undef
          this.hls.on(Hls.Events.MEDIA_ATTACHED, function() {
            _ref.hls.loadSource(_ref.hlsPlayBackUrl);
          });

          // eslint-disable-next-line no-undef
          this.hls.on(Hls.Events.FRAG_LOADED, function(_, b) {

            let origin = b.frag._url;
            origin = origin.split('.')[0].substr('https://'.length);
            _ref.network.edgeId = origin;
          });

          // eslint-disable-next-line no-undef
          this.hls.on(Hls.Events.FRAG_BUFFERED, function(data, b) {
            _ref.hlsStats = {..._ref.hlsStats, ...b.stats};
          });

          // eslint-disable-next-line no-undef
          this.hls.on(Hls.Events.FPS_DROP, function(name, data) {
            _ref.hlsStats.framesDropped = data.totalDroppedFrames;
          });

          // eslint-disable-next-line no-undef
          this.hls.on(Hls.Events.LEVEL_SWITCHED, function(name, data) {
            let {level} = data;

            _ref.hlsLevel = _ref.hls.levels[level];
          });

        } catch (e) {
          console.error(e);
        }

      } else if (this.$refs.video.canPlayType('application/vnd.apple.mpegurl')) {
        this.$refs.video.src = this.hlsPlayBackUrl;
      } else {
        // TODO: Alert that their device is not supported
      }

      this.player.on('timeupdate', event => {
        if (event.detail.plyr.currentTime < event.detail.plyr.duration) {
          _ref.hlsStats.position = event.detail.plyr.currentTime;
        }
      });

      this.player.on('play', function() {
        _ref.$gtag.event(`${_ref.video.type}_play`);
      });

      this.player.on('pause', function() {
        _ref.$gtag.event(`${_ref.video.type}_pause`);
      });

      this.player.on('qualitychange', function() {
        _ref.$gtag.event(`${_ref.video.type}_quality_change`);
      });

      this.player.on('stalled', function() {
        _ref.$gtag.event(`${_ref.video.type}_stalled`);
      });

      this.player.on('error', function() {
        _ref.$gtag.event(`${_ref.video.type}_error`);
      });

      this.player.on('ended', function() {
        window.top.postMessage('media_ended', '*');
        window.top.postMessage(`${_ref.video.type}_ended`, '*');
      });
    },

  },
  async mounted() {
    // let deviceId = this.did();

    // eslint-disable-next-line no-undef
    this.hls = new Hls(this.hlsConfig);

    this.parseUrlParams();
    this.$refs.plyr.mountPlayer();
    this.player = this.$refs.plyr.player;

    window.hls = this.hls;
    window.player = this.player;

    if (!await this.doSecurityChecks()) {
      return;
    }

    let reference = this;

    document.onkeydown = function(event) {
      const {key} = event;

      if (key === 'D') {
        reference.sfn = !reference.sfn;
        event.preventDefault();
      } else if (key === 'R') {
        window.hls.loadSource(reference.hlsPlayBackUrl);
      } else if (key === 'N') {
        window.hls.startLoad();
      } else if (key === 'M') {
        window.hls.recoverMediaError();
      }

    };

    this.doPlayerMounting();
  },
  beforeDestroy() {
    window.hls.destroy();
    window.player.destroy();
  },
};
</script>
