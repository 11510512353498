module.exports = {
  player: {
    autoplay: false,
    settings: ['captions', 'speed', 'loop'],
    controls: [
      'play-large',
      'play',
      'progress',
      'current-time',
      'mute',
      'volume',
      'captions',
      'settings',
      'fullscreen',
    ],
    fullscreen: {enabled: true, fallback: true, iosNative: true, container: null},
    captions: {active: false, language: 'auto', update: true},
    storage: {active: false, key: 'osi_player'},
  },
  hls: {
    startLevel: -1,
    enableWorker: true,
    progressive: false,

    testBandwidth: true,

    fetchSetup: function(context, initParams) {
      return new Request(context.url, {
        ...initParams,
      });
    },
  },
};
